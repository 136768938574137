import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { listStory, getStory } from '../../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
  Modal,
} from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'

import { getCategory } from '../../../helpers/helpers'
import Loader from '../../../components/Loader'
import { FaAd, FaBan, FaBookReader, FaCheck } from 'react-icons/fa'
import Message from '../../../components/Message'
import PaginateStory from '../../../components/PaginateStory'
import { register } from '../../../actions/userActions'
import { textAlign } from '@mui/system'
import Categories from '../../../components/Categories'
import Rating from '../../../components/Rating'
import SocialLogin from '../../../components/SocialLogin'
import { listStoryByCat } from '../../../actions/storyActions'
import Story from '../../../components/Story'
import HomeMeta from '../../../components/plots/meta/HomeMeta'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import AdminNav from '../../../components/AdminNav'
import {
  listInventory,
  approveInventory,
} from '../../../actions/inventoryActions'
import BookPaginate from '../../../components/paginate/BookPaginate'
import { getAdminStores } from '../../../actions/storeActions'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import TimeAgo from '../../../components/TimeAgo'
const StoreListScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1
  const dispatch = useDispatch()

  const [show, setShow] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [disable, setDisable] = React.useState(false)

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin

  const listAdminStores = useSelector((state) => state.listAdminStores)
  const { loading, error, success, stores, page, pages } = listAdminStores
  const handleChange = (event, value) => {
    history.push(`/admin/stores/page/${value}`)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    if (userLoggedIn && stores && stores.length == 0) {
      dispatch(getAdminStores(pageNumber))
    }
  }, [dispatch, userLoggedIn, pageNumber, stores, success])

  return (
    <>
      <AdminNav></AdminNav>
      <Container>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          success && (
            <Container>
              <Row>
                {stores.length > 0 &&
                  stores.map(
                    (store) =>
                      store.storeName && (
                        <Row>
                          <Col>
                            {store.storeName}
                            <br />
                            <TimeAgo timestamp={store.createdAt}></TimeAgo>
                          </Col>
                          <Col>
                            <div>
                              {' '}
                              Address: {store.address},{store.address1},
                              {store.city},{store.state} <br />
                              Phone: {store.phone}
                            </div>
                          </Col>
                          <Col>Stock: {store.numofbooks}</Col>
                          <Col>
                            Verified{' '}
                            {store.isVerified ? <FaCheck /> : <FaBan />}
                          </Col>
                          <Col>Books : {store.inventoryCount}</Col>
                        </Row>
                      )
                  )}
              </Row>
              <Row>
                <Stack spacing={1}>
                  <Pagination
                    count={pages}
                    page={page}
                    onChange={handleChange}
                    style={{
                      justifyContent: 'center',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      marginTop: '10px',
                      marginBottom: '10px',
                    }}
                  />
                </Stack>
              </Row>
            </Container>
          )
        )}
      </Container>
    </>
  )
}
{
}

export default StoreListScreen
