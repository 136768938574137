import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  ListGroupItem,
  Modal,
  Container,
} from 'react-bootstrap'
import { getCartDetailsFromStorage } from '../actions/cartActions.js'
import { Link } from 'react-router-dom'
import {
  FaCheckCircle,
  FaClock,
  FaHandsHelping,
  FaLock,
  FaTruck,
} from 'react-icons/fa'

const OrderSummary = ({ cart }) => {
  const dispatch = useDispatch()
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2)
  }

  return (
    <Container>
      {cart && cart.cartItems && cart.cartItems.length > 0 && (
        <Row className='order-summary2 text-center'>
          <Row>
            <Col xs={6}>
              <Link to='/cart'>View Cart </Link>
              <br /> {cart.cartItems && cart.cartItems.length} Items
            </Col>
            <Col xs={6}>
              Cart Total
              <br />
              <span className='productPrice'>
                {' '}
                &#8377;{cart.totalPrice && cart.totalPrice.toLocaleString()}/-
              </span>
            </Col>
          </Row>
          <Row>
            <div className='savedText'>
              <FaCheckCircle></FaCheckCircle> &nbsp;You Saved &#8377;
              {(
                cart.cartItems.reduce((acc, item) => acc + item.price, 0) -
                cart.cartPrice
              ).toLocaleString()}{' '}
              on this order
            </div>
          </Row>
          <Row style={{ fontSize: '14px' }}>
            <Col xs={6} className='capitalize-text'>
              <small>
                <FaHandsHelping></FaHandsHelping> Easy Returns
                <br />
                <FaLock style={{ color: '#333 !important' }}></FaLock>Secure
                Checkout
              </small>
            </Col>

            <Col xs={6} className='capitalize-text'>
              <small>Need Help? Call +919019158183</small>
            </Col>
          </Row>
        </Row>
      )}
    </Container>
  )
}

export default OrderSummary
