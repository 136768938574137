import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { FaBookMedical, FaBox, FaSearch, FaShoppingCart } from 'react-icons/fa'
import { Row, Col, Card, ListGroupItem, Container } from 'react-bootstrap'
const FooterMenu = ({ history }) => {
  const [keywords, setKeywords] = useState('')
  const location = useLocation()

  return (
    <Container>
      {location.pathname.indexOf('cart') > 0 ||
      location.pathname.indexOf('checkout') > 0 ? (
        ''
      ) : (
        <Row>
          <>
            <div className='footer-menu'>
              <div className='footer-menu-list'>
                <div className='footer-menu-list-item'>
                  <Link to={'/book/BookscartBox1/surprise-box-15-books'}>
                    <FaBox></FaBox>
                    <br />
                    Surprise Box
                  </Link>
                </div>
                <div className='footer-menu-list-item'>
                  <Link to={'/search'}>
                    <FaSearch></FaSearch>
                    <br />
                    Search
                  </Link>
                </div>
                <div className='footer-menu-list-item'>
                  <Link to={'/cart'}>
                    <FaShoppingCart></FaShoppingCart>
                    <br />
                    Cart
                  </Link>
                </div>
              </div>
            </div>
          </>
        </Row>
      )}
    </Container>
  )
}

export default FooterMenu
