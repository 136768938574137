import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Row,
  Col,
  ListGroup,
  Image,
  Form,
  Card,
  ListGroupItem,
  Container,
} from 'react-bootstrap'
import { FaBoxOpen, FaCheck, FaCheckCircle, FaCoins } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import {
  addToCart,
  removeFromCart,
  fireFBPixel,
  getCartDetailsFromStorage,
} from '../actions/cartActions.js'
import BoxProgressBar from '../components/BoxProgressBar'
import Rating from '../components/Rating'
import Loader from '../components/Loader'
import FooterMenu from '../components/FooterMenu'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { Typography, Button } from '@mui/material'
import { Helmet } from 'react-helmet'
const CartScreen = ({ match, location, history }) => {
  const qty = location.search ? Number(location.search.split('=')[1]) : 1
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const userCart = useSelector((state) => state.userCart)
  const { cart, loading, success } = userCart

  const userUpdatedCart = useSelector((state) => state.userCartRemoveItem)
  const { success: removeSuccess } = userUpdatedCart

  // const cartPrice = getCartPrice(cart.cartItems)
  const dispatch = useDispatch()

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(getCartDetailsFromStorage())
    if (match.params.id) {
      dispatch(addToCart(match.params.id, qty))
    }
  }, [dispatch, match.params.id, qty, removeSuccess])

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id))
  }

  const checkoutHandler = () => {
    dispatch(fireFBPixel(cart.totalPrice))
    if (userInfo) {
      history.push('/checkout/select-address')
    } else {
      history.push('/checkout')
    }
  }
  return (
    <Container style={{ minHeight: '750px' }}>
      <Helmet>
        <title>Cart</title>
        <meta charset='UTF-8' />
        <meta
          name='title'
          content={'Build Your Personal Library With Our Online Book Fair'}
        ></meta>
        <meta
          name='description'
          content={
            'Discover the joy of reading with our online book fair! Curate your own book box and choose any 7 books for just ₹999/-. Start building your personal library today'
          }
        ></meta>
        <meta
          name='keyword'
          content={('Online Book Fair', 'Your Own Book Box')}
        ></meta>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0'
        ></meta>
        <link rel='canonical' href={'https://www.99bookscart.com/cart'}></link>
      </Helmet>
      {!success ? (
        <Loader></Loader>
      ) : (
        <Container>
          <h1 className='text-center'>Cart</h1>

          {cart.empty ? (
            <Message>Your Cart is Empty</Message>
          ) : cart.cartItems && cart.cartItems.length === 0 ? (
            <Message>Your Cart is Empty</Message>
          ) : (
            <>
              <div>{cart && <BoxProgressBar cart={cart} />}</div>
              <Row>
                <Col md={8} xs={12}>
                  <ListGroupItem variant='flush'>
                    {cart.cartItems.map((item) => (
                      <ListGroup.Item
                        key={
                          item.product + item.variantName + item.variantValue
                        }
                      >
                        <Paper elevation={3}>
                          <Row className='bottom-border'>
                            <Col sm={2} xs={3}>
                              <LazyLoadImage
                                src={item.image}
                                width={'100%'}
                                alt={item.name}
                                className='cartImage'
                              />
                            </Col>
                            <Col
                              md={8}
                              xs={8}
                              style={{ paddingLeft: '5px', padding: '12px' }}
                            >
                              <Link
                                to={`/book/${item.isbn}/${convertToSlug(
                                  item.name
                                )}`}
                              >
                                <div className='cart-item-title'>
                                  {item.name}
                                </div>
                              </Link>
                              {/* <small className='text-muted'>by {item.author}</small><br/> */}
                              {!item.isPoster &&
                              !item.isEbook &&
                              item.isBookscartBox ? (
                                <small className='green-text very-small-text'>
                                  Available for Online Book Fair
                                  {/* <br />
                                <span className='text-muted'>
                                  9 books for ₹999
                                </span>*/}
                                  <br />
                                </small>
                              ) : (
                                !item.isEbook &&
                                !item.isBox &&
                                !item.isPoster && (
                                  <small className='red-text very-small-text'>
                                    Not Available for Online Book Fair
                                    <br />
                                  </small>
                                )
                              )}
                              {item.isEbook && <div>EBook</div>}
                              {item.variantName && (
                                <>
                                  <div>
                                    <strong>{item.variantName}</strong>
                                  </div>
                                  <div>
                                    {!item.isPoster &&
                                      item.boxtype &&
                                      (item.boxtype == 1
                                        ? '15 Books'
                                        : item.boxtype == 2
                                        ? '50 Books'
                                        : '100 Books')}
                                  </div>
                                </>
                              )}
                              <span className='cart-productPrice'>
                                &#8377;
                                {item.salePrice &&
                                  item.salePrice.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                              </span>{' '}
                              <strike>
                                &#8377;
                                {item.price &&
                                  item.price.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                              </strike>
                              {item.product == '6679aa0d2c7c67735604a80b' && (
                                <div style={{ color: 'red' }}>
                                  <br />
                                  FREE with Surprise Box
                                </div>
                              )}
                              {item.sellerName && !item.isBox && (
                                <div>
                                  <Typography variant='caption'>
                                    Sold by {item.sellerName}
                                  </Typography>
                                </div>
                              )}
                            </Col>
                            <Col
                              xs={1}
                              style={{
                                textAlign: 'right',
                              }}
                            >
                              <Button
                                type='button'
                                style={{ float: 'right', marginRight: '25px' }}
                                onClick={() =>
                                  removeFromCartHandler(item.product)
                                }
                              >
                                X
                              </Button>
                            </Col>
                          </Row>
                        </Paper>
                      </ListGroup.Item>
                    ))}
                  </ListGroupItem>
                </Col>
                <Col sm={4} xs={12} style={{ paddingTop: '18px' }}>
                  <Card>
                    <ListGroup variant='flush'>
                      <ListGroup.Item>
                        <div>
                          <strong>Cart Value</strong> (
                          <strike>
                            &#8377;
                            {cart.cartItems
                              .reduce((acc, item) => acc + item.price, 0)
                              .toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                          </strike>
                          )
                        </div>
                        <div>
                          Total {cart.cartItems.length} Items{' '}
                          <strong>
                            Cart total{' '}
                            <span className='cart-productPrice'>
                              &#8377;
                              {cart.cartPrice &&
                                cart.cartPrice.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                            </span>
                          </strong>
                        </div>
                        {/*<div>
                          <strong>
                            Discount{' '}
                            <span className='cart-productPrice'>
                              &#8377;
                              {cart.discountPrice &&
                                cart.discountPrice.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                            </span>
                          </strong>
                        </div>
                         <div>
                          <strong>
                            Shipping Charges{' '}
                            <span className='cart-productPrice'>
                              &#8377;
                              {cart.shippingPrice &&
                                cart.shippingPrice.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                            </span>
                          </strong>
                        </div>
                        <div>
                          <strong>
                            Payable Total{' '}
                            <span className='cart-productPrice'>
                              &#8377;
                              {cart.totalPrice &&
                                cart.totalPrice.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                            </span>
                          </strong>
                        </div> */}
                        <div className='savedText'>
                          <FaCheckCircle></FaCheckCircle> &nbsp;You Saved
                          &#8377;
                          {(
                            cart.cartItems.reduce(
                              (acc, item) => acc + item.price,
                              0
                            ) - cart.cartPrice
                          ).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{' '}
                          on this order
                        </div>
                        {cart.cartPrice > 0 && (
                          <Typography
                            variant='caption'
                            xs={{ mt: 2 }}
                            className='cartCoins'
                          >
                            <h6 style={{ fontSize: '14px' }}>
                              <FaCoins className='orange'></FaCoins> Earn{' '}
                              {cart.cartPrice / 50} coins on this order
                            </h6>
                          </Typography>
                        )}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <br />
                        <Row
                          style={{ marginLeft: 'auto', marginRight: 'auto' }}
                          className='text-center'
                        >
                          <Col>
                            <Button
                              variant='contained'
                              className='theme-button'
                              disabled={cart.cartItems.length === 0}
                              style={{ width: '100%', marginBottom: '28px' }}
                              onClick={checkoutHandler}
                            >
                              Checkout
                            </Button>
                            <br />
                            <br />
                            <br />
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </Container>
      )}
      <FooterMenu></FooterMenu>
    </Container>
  )
}
function convertToSlug(Text) {
  return Text.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}
export default CartScreen
