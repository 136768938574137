import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'

import { Table, Form, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listAbandonCart } from '../actions/cartActions'
import AdminNav from '../components/AdminNav'
import AbandoncartSearchBox from '../components/AbandoncartSearchBox'
import { Route } from 'react-router-dom'

const AbandonCartListScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const abandonCarts = useSelector((state) => state.abandonCartList)
  const { loading, error, abandonCartList: cartList } = abandonCarts
  const pageNumber = match.params.pageNumber || 1
  const keyword = match.params.keyword || null
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    window.scrollTo(0, 0)

    if (userInfo && userInfo.isAdmin) {
      dispatch(listAbandonCart(pageNumber, keyword))
    } else {
      history.push('/login')
    }
  }, [dispatch, history, userInfo])

  return (
    <Container>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Row>
          <AdminNav></AdminNav>
          <Route
            render={({ history }) => <AbandoncartSearchBox history={history} />}
          />
          <Row>
            <Col>
              <h4>Abandon Cart</h4>
            </Col>
            <Col>
              <Link to='/' className='btn btn-light float-right'>
                Go back
              </Link>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table striped bordered hover responsive className='table-sm'>
                <thead>
                  <tr>
                    <th>USER</th>
                    <th>Converted</th>
                    <th>DATE</th>
                  </tr>
                </thead>
                <tbody>
                  {cartList.map((cart) => (
                    <tr key={cart._id}>
                      <td>
                        {cart.user
                          ? cart.user.name
                          : cart.shippingAddress &&
                            cart.shippingAddress.phone &&
                            cart.shippingAddress.phone}
                      </td>
                      <td>
                        {cart.isConverted ? (
                          <i className='fas fa-check'></i>
                        ) : (
                          <i className='fas fa-times'></i>
                        )}
                      </td>
                      <td>
                        <small>{getISTTime(cart.createdAt)}</small>
                      </td>
                      <td>
                        <LinkContainer
                          to={`/admin/abandoncartdetails/${cart._id}`}
                        >
                          <Button className='btn-sm' variant='light'>
                            Details
                          </Button>
                        </LinkContainer>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Row>
      )}
    </Container>
  )
}
const getISTTime = (date) => {
  let d = new Date(date)
  let newData = d.getTime() + 5.5 * 60 * 60
  let ist = new Date(newData)
  return (
    ist.getDate() +
    '/' +
    ist.getMonth() +
    '/' +
    ist.getFullYear() +
    ' ' +
    ist.getHours() +
    ':' +
    ist.getMinutes()
  )
}
export default AbandonCartListScreen
